import React, { useState } from 'react';
import axios from 'axios';

const FatSecretSearch = ({ onSelect }) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchIngredients = async (searchQuery) => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://platform.fatsecret.com/rest/server.api', {
        params: {
          method: 'foods.search',
          search_expression: searchQuery,
          format: 'json',
          oauth_consumer_key: 'f9196734c37d4bdc913d1ba0a3b22363',
          oauth_signature_method: 'HMAC-SHA1',
          oauth_timestamp: Math.floor(Date.now() / 1000),
          oauth_nonce: Math.random().toString(36).substring(7),
          oauth_version: '1.0'
        },
        headers: {
          'Authorization': `OAuth oauth_consumer_key="f9196734c37d4bdc913d1ba0a3b22363"`
        }
      });
      setResults(response.data.foods.food);
    } catch (error) {
      console.error('Error searching ingredients:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 2) {
      searchIngredients(value);
    } else {
      setResults([]);
    }
  };

  return (
    <div className="fatsecret-search">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search ingredients..."
        className="search-input"
      />
      {isLoading && <div className="loading-spinner">Loading...</div>}
      <ul className="search-results">
        {results.map((food) => (
          <li key={food.food_id} onClick={() => onSelect(food)}>
            {food.food_name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FatSecretSearch;