// RecipeEditor.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Slider from '../components/Slider/Slider';
import IngredientsList from '../components/Ingredients/IngredientsList';
import Tabs from '../components/Tabs/Tabs';
import SaveContainer from '../components/SaveContainer/SaveContainer';
import PdfGenerator from '../components/PdfGenerator/PdfGenerator';
import { saveRecipe, getRecipes } from '../firebaseFunctions';
import { uploadToImgBB } from '../utils/imgbbUploader';
import { uploadToCloudinary } from '../utils/videoUploader';
import axios from 'axios';
import AlternativePdfGenerator from '../components/PdfGenerator/AlternativePdfGenerator';

function RecipeEditor() {
  const [slides, setSlides] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [steps, setSteps] = useState([]);
  const [recipe, setRecipe] = useState('');
  const [recipeName, setRecipeName] = useState('');
  const [originalRecipeName, setOriginalRecipeName] = useState('');
  const [videos, setVideos] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mediaUrls, setMediaUrls] = useState({ slides: [], videos: [] });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCancelTokens, setUploadCancelTokens] = useState([]);
  const [totalCalories, setTotalCalories] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem('recipeEditorState'));
    if (savedState) {
      setSlides(savedState.slides || []);
      setIngredients(savedState.ingredients || []);
      setSteps(savedState.steps || []);
      setRecipe(savedState.recipe || '');
      setRecipeName(savedState.recipeName || '');
      setVideos(savedState.videos || []);
      setVideoFiles(savedState.videoFiles || []);
      setTotalCalories(savedState.totalCalories || 0);
    }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const currentState = {
      slides,
      ingredients,
      steps,
      recipe,
      recipeName,
      videos,
      videoFiles,
      totalCalories,
      selectedCategory,
    };
    localStorage.setItem('recipeEditorState', JSON.stringify(currentState));
  }, [slides, ingredients, steps, recipe, recipeName, videos, videoFiles, totalCalories,selectedCategory]);

  useEffect(() => {
    if (id !== 'new') {
      if (location.state?.recipe) {
        loadRecipeData(location.state.recipe);
      } else {
        loadRecipe(id);
      }
    }
    return () => {
      // Clear localStorage only when component unmounts
      if (id === 'new') {
        // localStorage.removeItem('recipeEditorState');
      }
    };
  }, [id, location]);

  const loadRecipeData = (recipeData) => {
    setSlides(recipeData.slides || []);
    setIngredients(recipeData.ingredients || []);
    setSteps(recipeData.steps || []);
    setRecipe(recipeData.description || '');
    setRecipeName(recipeData.name || '');
    setOriginalRecipeName(recipeData.name || '');
    setVideos(Array.isArray(recipeData.videos) ? recipeData.videos : []);
    setMediaUrls({
      slides: recipeData.slides || [],
      videos: Array.isArray(recipeData.videos) ? recipeData.videos : [],
    });
    setTotalCalories(recipeData.totalCalories || 0);
    setSelectedCategory(recipeData.category || 'all')
  };

  const loadRecipe = async (recipeId) => {
    try {
      const recipes = await getRecipes();
      const currentRecipe = recipes.find((r) => r.id === recipeId);
      if (currentRecipe) {
        loadRecipeData(currentRecipe);
      }
    } catch (error) {
      console.error('Ошибка при загрузке рецепта:', error);
    }
  };

  const uploadToCloudinaryBatch = async (files) => {
    if (files.length === 0) return [];
    const newVideoUrls = [];
    const cancelTokens = [];

    try {
      for (const videoFile of files) {
        const source = axios.CancelToken.source();
        cancelTokens.push(source);
        const url = await uploadToCloudinary(
          videoFile,
          (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress((prev) => Math.min(prev + percentCompleted, 100));
          },
          source
        );
        newVideoUrls.push(url);
      }

      setUploadCancelTokens(cancelTokens);
      return newVideoUrls;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Загрузка видео отменена пользователем');
      } else {
        console.error('Ошибка при загрузке видео на Cloudinary:', error);
        throw error;
      }
    }
  };

  const saveRecipeToFirestore = async (selectedCategory) => {
    if (!recipeName.trim()) {
      alert('Пожалуйста, введите название рецепта');
      return;
    }
  
    const isNameChanged = recipeName !== originalRecipeName;
    const newRecipeId = id === 'new' || isNameChanged ? Date.now().toString() : id;
    setIsLoading(true);
  
    try {
      const slideUrls = await Promise.all(
        slides
          .filter((slide) => slide.startsWith('data:') || slide instanceof File)
          .map(async (slideData) => {
            if (slideData instanceof File) {
              return await uploadToImgBB(slideData);
            } else {
              const response = await fetch(slideData);
              const blob = await response.blob();
              return await uploadToImgBB(blob);
            }
          })
      );
  
      const existingSlideUrls = slides.filter((slide) => slide.startsWith('http'));
      const updatedSlideUrls = [...existingSlideUrls, ...slideUrls];
  
      const newVideoUrls = await uploadToCloudinaryBatch(videoFiles);
      const existingVideoUrls = Array.isArray(videos)
        ? videos.filter((url) => !url.startsWith('blob:'))
        : [];
      const updatedVideoUrls = [...existingVideoUrls, ...newVideoUrls];
  
      // Округление значений КБЖУ до одного знака после запятой
      const roundedIngredients = ingredients.map((ing) => ({
        ...ing,
        calories: parseFloat(ing.calories || 0).toFixed(1),
        proteins: parseFloat(ing.proteins || 0).toFixed(1),
        fats: parseFloat(ing.fats || 0).toFixed(1),
        carbs: parseFloat(ing.carbs || 0).toFixed(1),
      }));
  
      const recipeData = {
        id: newRecipeId,
        name: recipeName,
        ingredients: roundedIngredients, // Используем округленные значения
        steps,
        description: recipe,
        slides: updatedSlideUrls,
        videos: updatedVideoUrls,
        totalWeight: ingredients.reduce((sum, ing) => sum + parseFloat(ing.readyWeight || 0), 0),
        totalCost: ingredients.reduce((sum, ing) => sum + parseFloat(ing.cost || 0), 0),
        totalCalories: parseFloat(totalCalories).toFixed(1), // Округляем общие калории
        category: selectedCategory,
      };
  
      await saveRecipe(recipeData);
      alert(`Рецепт успешно сохранен с ID: ${newRecipeId}`);
  
      setVideos(updatedVideoUrls);
      setSlides(updatedSlideUrls);
      setVideoFiles([]);
      setOriginalRecipeName(recipeName);
      navigate('/recipes');
  
      // localStorage.removeItem('recipeEditorState');
    } catch (error) {
      console.error('Ошибка при сохранении рецепта:', error);
      alert('Произошла ошибка при сохранении рецепта');
    } finally {
      setIsLoading(false);
      setUploadProgress(0);
      setUploadCancelTokens([]);
    }
  };
  

  const cancelUploads = () => {
    uploadCancelTokens.forEach((token) =>
      token.cancel('Загрузка видео отменена пользователем.')
    );
  };



  


  return (
    <div className="editor">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          {uploadProgress > 0 && uploadProgress < 100 && (
            <div className="upload-progress">
              <div className="progress-bar">
                <div
                  className="progress-fill"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <span>Загрузка видео: {uploadProgress}%</span>
              <button className="cancel-button" onClick={cancelUploads}>
                Отмена загрузки
              </button>
            </div>
          )}
        </div>
      )}
      <Slider
        slides={slides}
        setSlides={setSlides}
        mediaUrls={mediaUrls}
        setMediaUrls={setMediaUrls}
      />
      <IngredientsList
        ingredients={ingredients}
        setIngredients={setIngredients}
        onTotalCaloriesChange={setTotalCalories}
      />
      <Tabs
        steps={steps}
        setSteps={setSteps}
        recipe={recipe}
        setRecipe={setRecipe}
        videos={videos}
        setVideos={setVideos}
        videoFiles={videoFiles}
        setVideoFiles={setVideoFiles}
        mediaUrls={mediaUrls}
        setMediaUrls={setMediaUrls}
      />
      <SaveContainer
        recipeName={recipeName}
        setRecipeName={setRecipeName}
        onSave={saveRecipeToFirestore}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}

      />
      <PdfGenerator
        recipe={{
          name: recipeName,
          ingredients,
          steps,
          description: recipe,
        }}
      />
      <AlternativePdfGenerator
        recipe={{
          name: recipeName,
          ingredients,
          steps,
          description: recipe,
          imageUrls: mediaUrls.slides,
        }}
      />

    </div>
  );
}

export default RecipeEditor;
